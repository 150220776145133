$primary-color: #38C3C8;
$primary-background-color: #38C3C8;
$primary-background-color-75: rgba(56, 195, 200, 0.75);
$primary-background-color-50: rgba(56, 195, 200, 0.5);
$primary-background-color-25: rgba(56, 195, 200, 0.25);
$primary-background-color-light: #e6eaed;
$primary-background-color-dark: #38C3C8;

$secondary-color: #4D4D4D;
$secondary-background-color: #4D4D4D;
$secondary-background-color-75: rgba(77, 77, 77,  0.75);
$secondary-background-color-50: rgba(77, 77, 77, 0.5);
$secondary-background-color-25: rgba(77, 77, 77, 0.35);
$secondary-background-color-light: #e6eaed;


$red-color: #e63744;
$red-background-color: #e63744;

$text-color: #333333;
$title-color: darken($text-color, 5%);
$h1-color: #62435d;

// Cookie
$mainCookieBG: #4D4D4D;
$cookieLinkColor: #fff;
$cookieSlideActiveColor: darken($mainCookieBG, 30%);
$cookieCloseIconColorOnHover: red;
$cookiePrivacyLinkColorOnHover: darken($mainCookieBG, 30%);

$cookieBtnBG: #fff;
