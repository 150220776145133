html {
	width: 100%;
}

body {
	width: 100%;
	font-family: sans-serif !important;
	font-size: 15px;
	line-height: 1.7;
	color: $text-color;
}

/* Heading */

h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
	color: $title-color;
	line-height: 1.2;
	font-family: sans-serif !important;
}

h1 {
	margin: 0 0 25px;
	font-size: 30px;
	font-weight: 400;
	color: $h1-color;
}

h2 {
	margin: 5px 0 20px;
	font-size: 20px;
}

h3 {
	margin: 5px 0 10px;
	font-size: 17px;
}

header {
	.logo{
		.logo-img.desktop{
			height: 80px;
		}
	}
	&.high{
		.logo-img.nvm {
			top: 100px;
			left: 10px;
		}
		.phonenumber{
			left: 85px;
			top: 110px;
		}
	}
	&.narrow {
		.logo-img.desktop{
			top: 10px;
			height:50px;
			-webkit-transition: all 0.25s linear;
			transition: all 0.25s linear;

		}
		.logo-img.nvm {
			top: 15px;
			left: 155px;
		}
		.phonenumber{
			top: 30px;
			left: 230px;
		}
	}
}
/* footer */
.aligned{
    &.left-align{
        .logo-left{
            .footer-nvm svg{
                fill: #262626;
            }
            .footer-funda svg{
                fill: #262626;
            }
        }
        .logo-right{
            .footer-nwwi svg{
                fill: #262626;
            }
            .footer-nrvg svg{
                fill: #262626;
            }
        }
    }
}
